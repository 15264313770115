
<template>
  <div class="d-flex flex-column h-100" style="overflow: auto">
    <div class="d-flex flex-row mb-3 justify-content-center">
      <div
        class="d-flex flex-column justify-content-center"
        style="min-width: 20vw; max-width: 50vw"
      >
        <div style="height: 1vh"></div>

        <fieldFile
          v-if="!file"
          :textBtn="$t('advert.addFile')"
          :multiselect="false"
          :acceptTypes="'*'"
          :getBinary="true"
          :hidingLoadBtn="true"
          @onError="onErr"
          @finishUpload="setData"
        ></fieldFile>

        <div
          v-if="file"
          style="
            min-height: 45px;
            padding: 10px 5px;
            margin: 0px;
            margin-bottom: 0.5rem;
            justify-content: space-between;
          "
          class="d-flex w-100 flex-row align-items-center v-btn v-theme--ORANGE_THEME border-choosen"
          @click="
            file = null;
            file_name = null;
          "
        >
          <img class="remove" src="@/assets/icons/logo_file.svg" />
          <span
            style="
              flex: 1 0 auto;
              font-size: 1.3rem;
              line-height: 1.3rem;
              text-transform: none;
            "
          >
            {{ file_name }}
          </span>
          <span class="remove">
            <img src="@/assets/icons/icon-remove.svg" />
          </span>
        </div>

        <div style="height: 1vh"></div>

        <base-multiselect
          v-if="namespaces.length > 0"
          class="base-multiselect__item"
          :placeholder="$t('mlpage.select') + ' ' + $t('mlpage.namespace')"
          :items="namespaces"
          :value="sel_name"
          :multiple="false"
          :labelProp="'name'"
          :closeOnSelect="true"
          :search="true"
          @select="
            (payload) => {
              sel_name = payload;
            }
          "
        ></base-multiselect>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center">
      <base-button
        :variant="'pure'"
        className="v-btn v-theme--ORANGE_THEME bg-success v-btn--density-default v-btn--size-default v-btn--variant-flat"
        @click="saveData"
      >
        <h2>{{ $t("mlpage.run_exec") }}</h2>
      </base-button>

      <base-checkbox
        style="margin-left: 5px"
        :text="$t('mlpage.check_intersection')"
        :value="check_intersection"
        :modelValue="check_intersection"
        @update:modelValue="check_intersection = $event"
        :trueValue="true"
        :falseValue="false"
      ></base-checkbox>
    </div>

    <spinner v-if="spinnerShow"></spinner>
  </div>
</template>


<script>
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseMultiselect from "@/components/BaseMultiselect_new.vue";
import fieldFile from "@/components/formGenerator/builder/fields/fieldFile.vue";
import EntityApi from "@/api/entityApi";
import Spinner from "../Spinner.vue";

export default {
  name: "AutoAnnotation-page",
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
    data: {
      type: Object,
    },
  },
  emits: ["closeModalf"],
  components: {
    BaseCheckbox,
    BaseButton,
    BaseMultiselect,
    Spinner,
    fieldFile,
  },
  data() {
    return {
      spinnerShow: false,

      namespaces: [],
      file: null,
      file_name: null,
      sel_name: null,
      check_intersection: false,
    };
  },
  methods: {
    saveData() {
      if (!this.file) {
        this.$notify(
          {
            group: "alert",
            type: "error",
            title: this.$t("lables.error"),
            text: this.$t("mlpage.select") + " " + this.$t("mlpage.file"),
          },
          3000
        );
      }
      if (!this.sel_name) {
        this.$notify(
          {
            group: "alert",
            type: "error",
            title: this.$t("lables.error"),
            text: this.$t("mlpage.select") + " " + this.$t("mlpage.namespace"),
          },
          3000
        );
      }

      if (this.file && this.sel_name) {
        this.spinnerShow = true;

        let url = `${process.env.VUE_APP_API_URL}/ml/namespaces/${this.sel_name.id}?check_intersection=${this.check_intersection}`;
        this.sendFile(url, "post", this.file)
          .then((resp) => {
            this.spinnerShow = false;
            console.log(resp);

            if (resp && resp.url && resp.status) {
              this.$notify(
                {
                  group: "alert",
                  type: "error",
                  title: this.$t("lables.opps"),
                  text:
                    this.$t("lables.error") +
                    " " +
                    resp.status +
                    (resp.statusText ? ", " + resp.statusText : ""),
                },
                3000
              );
            } else {
              this.$notify(
                {
                  group: "alert",
                  type: "info",
                  title: this.$t("lables.success"),
                  text: this.$t("mlpage.saved"),
                },
                3000
              );
              this.$emit("closeModalf", null);
            }
          })
          .catch(() => {
            this.spinnerShow = false;
          });
      } else {
        return;
      }
    },
    onFileAdded(fieldName, newValue) {
      this.file_name = newValue;
    },
    onErr() {
      this.$notify(
        {
          group: "alert",
          type: "error",
          title: this.$t("lables.error"),
          text: this.$t("advert.files_upl"),
        },
        3000
      );
    },
    setData(data) {
      this.file = data;
    },
    async getnamespaces() {
      let res = await EntityApi.getEntityItems("ml_server_namespace");
      console.log(res);
      if (res && res.length > 0) {
        res = res.filter((item) => item.ml_server.id == this.id);
        this.namespaces = res;
      }
    },
    async sendFile(url, method, data) {
      let response = await fetch(url, {
        method: method,
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: data,
      }).catch((error) => {
        console.error("Error:", error);
        this.$notify(
          {
            group: "alert",
            type: "error",
            title: this.$t("lables.opps"),
            text: this.$t("lables.error"),
          },
          3000
        );
      });

      let res = await response.json();
      if (response.ok) {
        return res;
      } else {
        return response;
      }
    },
  },
  provide() {
    return {
      updateModelValue: this.onFileAdded,
      validateAllField: null,
      setError: null,
      clearValidationErrors: null,
      clearValidationErrorsForField: null,
    };
  },
  mounted() {
    this.getnamespaces();
  },
  beforeUnmount() {},
  sockets: {},
  computed: {},
  watch: {
    /*"modes.addingPoints": {
      handler(val) {
        
      },
      deep: true,
    },*/
  },
};
</script>
<style lang="scss" scoped>
.base-multiselect__value {
  border-color: #e2e8f0 !important;
  background-color: red;
}

.value__list {
  flex-wrap: wrap;
}

.border-choosen {
  border: 1px solid #000;
  border-radius: 4px;
  &:hover {
    border-color: #acacff;
    cursor: pointer;
  }
}
.remove {
  height: 1.5rem;
  width: 1.5rem;
}
</style>